var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('el-table',{ref:"scheduleTable",staticClass:"feed-entry-table card-row-table",attrs:{"data":_vm.getArrScheds,"cell-class-name":"schedule-cell","header-row-class-name":"card-row-table__header-row","header-cell-class-name":"feed-entry-class-header","row-class-name":"card-row-table__row","row-key":"_id","height":"calc(100vh - var(--height-gutter))","empty-text":_vm.$t('PM_no_pond_sch_mode')},on:{"selection-change":_vm.handleSelectionChange}},[_c('el-table-column',{attrs:{"width":"25px","fixed":""},scopedSlots:_vm._u([{key:"header",fn:function(ref){
var $index = ref.$index;
return [_c('el-checkbox',{key:$index,attrs:{"value":_vm.checkAll,"indeterminate":_vm.isIndeterminate},on:{"change":_vm.handleCheckAllChange}})]}},{key:"default",fn:function(scope){return [_c('el-checkbox',{attrs:{"value":scope.row.selected},on:{"change":function($event){return _vm.handleCheckedSchedulesChange($event, scope.row.pond_id)}}})]}}])}),_vm._v(" "),_c('el-table-column',{attrs:{"min-width":100,"label":_vm.tableColumns['pond_title'].label,"fixed":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('span',{staticStyle:{"white-space":"break-spaces"}},[_vm._v(_vm._s(row.pond_title))])]}}])}),_vm._v(" "),_c('el-table-column',{attrs:{"min-width":130,"label":_vm.tableColumns['feed_type'].label},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('er-select',{attrs:{"value":row.feed_type_id,"size":"mini","placeholder":_vm.$t('select_feed')},on:{"change":function($event){return _vm.handleChangeInFeedType($event, row)}}},_vm._l((_vm.getArrFeedTypes),function(feedType){return _c('el-option',{key:feedType._id,attrs:{"label":((feedType.name) + " " + (feedType.feed_type === undefined ? '' : '-') + " " + (feedType.feed_type === undefined ? '' :  feedType.feed_type)),"value":feedType._id}})}),1)]}}])}),_vm._v(" "),_c('el-table-column',{attrs:{"min-width":100},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('el-input-number',{attrs:{"controls":false,"precision":1,"value":row.feed,"size":"mini","min":0},on:{"change":function($event){return _vm.handleChangeTotalFeedAtPond($event, row)}}})]}}])},[_c('template',{slot:"header"},[_c('center',[_vm._v(_vm._s(_vm.tableColumns["total_feed"].label))])],1)],2),_vm._v(" "),_c('el-table-column',{attrs:{"min-width":100,"prop":"ocf"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('el-input-number',{attrs:{"controls":false,"value":row.ocf,"precision":0,"step":50,"size":"mini","min":200,"max":2500},on:{"change":function($event){return _vm.handleChangeInOCF($event, row)}}})]}}])},[_c('template',{slot:"header"},[_c('center',[_vm._v(_vm._s(_vm.tableColumns["ocf"].label))])],1)],2),_vm._v(" "),_vm._l((_vm.getSelectedFtTS),function(ts,index){return _c('el-table-column',{key:index,attrs:{"minWidth":80},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [(row.time_slots[index])?_c('div',{staticClass:"time-slot-cell"},[_c('el-input-number',{key:row.time_slots[index]._id,attrs:{"controls":false,"precision":2,"min":0,"size":"mini","value":row.time_slots[index].feed,"disabled":_vm.disableTimeSlot(row.time_slots, index, row.pond_id)},on:{"change":function($event){return _vm.handleChangeTFAtTS($event, row, index)}}})],1):_vm._e()]}}],null,true)},[_c('template',{slot:"header"},[_c('center',[_c('p',{staticClass:"small-text"},[_c('er-mode-color',{attrs:{"mode":ts.mode}},[_vm._v(_vm._s(("" + (_vm.$t("S")) + (index + 1)))+": "+_vm._s(ts.feed_percentage)+"%")])],1),_vm._v(" "),_c('p',{staticClass:"small-text"},[_vm._v(_vm._s(_vm.formatTime(ts.s_time)))])])],1)],2)})],2)}
var staticRenderFns = []

export { render, staticRenderFns }