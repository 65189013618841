<!-- Copyright (C) Eruvaka Technologies Pvt Ltd - All Rights Reserved * Unauthorized copying of this file, via any medium is strictly prohibited * Proprietary and confidential * 2021 -->
<!--
File Name: editPond.vue
Description: This file contains form required to edit the pond
-->
<template>
  <div id="edit-pond">
    <div id="edit-pond-element">
      <el-card class="action-display-panel">
        <template slot="header">
          <h3>{{ $t("Map_edit_pond") }}</h3>
        </template>
        <er-form
          :inline="true"
          :model="formPond"
          :ref="formName"
          size="small"
          :hide-required-asterisk="true"
          class="form-container"
          label-position="left"
        >
          <el-form-item
            :label="$t('Comn_pond_name')"
            prop="title"
            :error="
              ekmm__castErrorKeyToLang(ehm__errMessagesObject['title']) ||
              ehm__errMessagesObject.title
            "
          >
            <el-input
              v-model="formPond.title"
              maxlength="50"
              :placeholder="$t('Comn_pond_name')"
            ></el-input>
          </el-form-item>
          <el-form-item
            :label="ftm__capitalize($tc('Comn_location.case.lower', 1))"
            prop="location_id"
            :error="ekmm__castErrorKeyToLang(ehm__errMessagesObject['name'])"
          >
            <er-select
              value-key="name"
              allow-create
              filterable
              :placeholder="ftm__capitalize($tc('Comn_location.case.lower', 1))"
              popper-class="location-select__popper"
              default-first-option
              :value="currPondLocation"
              @input="handleChangeInLocation"
              class="select-location"
            >
              <!-- :filter-method="filterLocations" -->
              <el-option
                v-for="location in getUserLocationsObj"
                :key="`${location.name} ${location._id}`"
                :value="location.name.toLowerCase()"
                :label="ftm__capitalize(location.name)"
              ></el-option>
            </er-select>
          </el-form-item>
          <!-- {{ $t("Pond_size", { units: $t(pondSizeUnits.lang_key) }) }} -->
          <el-form-item
            :label="$tc('Pond_size')"
            prop="size"
            :error="ekmm__castErrorKeyToLang(ehm__errMessagesObject['size'])"
          >
            <el-input
              type="number"
              :value="parseFloat(Number(formPond.size).toFixed(2))"
              :controls="false"
              :placeholder="$tc('Pond_size')"
              :min="0.1"
              :precision="2"
              :clearable="false"
              @input="handleSizeChange"
              @change="handleSizeChange"
            ></el-input>
            <div class="error-msg" v-if="isError">{{$t('Pond_size_error_msg', { maxArea: this.maxArea, minArea: this.minArea })}}</div>
          </el-form-item>

          <el-form-item :label="$t('Comn_units')">
            <er-select :value="formPond.units" @change="handleChangeAreaUnits">
              <el-option
                v-for="(unit, key) in units"
                :key="unit"
                :label="unit"
                :value="key"
              ></el-option>
            </er-select>
          </el-form-item>
          <!-- class="edit-pond-input-size" -->
          <el-form-item
            :label="$t('Comn_stocking_date')"
            :error="
              ekmm__castErrorKeyToLang(ehm__errMessagesObject.cultivation_date)
            "
          >
            <el-col :span="24">
              <el-date-picker
                class="date-picker-stock-date"
                v-model="formPond.cultivation_date"
                type="date"
                value-format="yyyy-MM-dd"
                :format="$commonUtils.DATE_OBJ_DATE_FORMAT_WITH_YEAR"
                :clearable="false"
                :picker-options="pickerOptions"
                :placeholder="$t('Comn_stocking_date')"
              ></el-date-picker>
            </el-col>
          </el-form-item>
          <!-- :error="ekmm__castErrorKeyToLang(ehm__errMessagesObject.cultivation_date)" -->

          <el-form-item
            :label="$t('Pond_pls_stocked')"
            prop="post_larva_stocked"
            :error="
              ekmm__castErrorKeyToLang(
                ehm__errMessagesObject['post_larva_stocked']
              )
            "
          >
            <el-input
              :min="0"
              v-model="formPond.post_larva_stocked"
              :placeholder="$t('Pond_pls_stocked')"
              max="1000000000"
            ></el-input>
          </el-form-item>

          <el-form-item
            :label="$t('initial_abw')"
            prop="initial_abw"
            :error="
              ekmm__castErrorKeyToLang(ehm__errMessagesObject['initial_abw'])
            "
          >
            <el-input-number
              v-model="formPond.initial_abw"
              :placeholder="$t('initial_abw')"
              :controls="false"
            ></el-input-number>
          </el-form-item>
          <el-form-item
            :label="$t('Pond_hatchery_name')"
          >
            <er-input
              v-model="formPond.hatchery_name"
              :placeholder="$t('Pond_hatchery_name')"
              :disabled="true"
            ></er-input>
          </el-form-item>
          <div class="btn-group">
            <er-button
              btnType="save"
              :showIcon="true"
              :showLabel="true"
              size="mini"
              @click="saveDetails"
              :disabled="isError"
            >
            </er-button>
            <er-button
              btnType="cancel"
              :showLabel="true"
              size="mini"
              @click="resetEditingMode"
            >
              {{ $t("Comn_cancel") }}
            </er-button>
          </div>
        </er-form>
      </el-card>
    </div>
  </div>
</template>

<script>
/**
 *TODO :
 *1.need to disable save button until an overlay is drawn
 *2.need to correct error of positioning name for the concave polygons
 **/

import Pond from "@/model/pond";
import { mapActions, mapGetters } from "vuex";
import errorHandlerMixin from "@/mixins/errorHandlerMixin";
import filtersMixin from "@/mixins/filtersMixin";
import errorKeyMapMixin from "@/mixins/errorKeyMapMixin";
import userPreferenceMixin from "@/mixins/userPreferenceMixin";
import datesHandlerMixin from "@/mixins/datesHandlerMixin";
import redirectsMixin from "@/mixins/redirectsMixin";
import notificationMixin from "@/mixins/notificationMixin";
export default {
  mixins: [
    datesHandlerMixin,
    errorHandlerMixin,
    userPreferenceMixin,
    errorKeyMapMixin,
    filtersMixin,
    redirectsMixin,
    notificationMixin
  ],
  props: ["map"],
  data: function () {
    return {
      formPond: new Pond(),
      pondDetailsDialog: false,
      changeSize: false,
      oldunit: "",
      newunit: "",
      oldsize: "",
      newsize: "",
      currPondLocation: "",
      noChangeInLocation: false,
      ehm__errMessagesObject: {
        title: "",
        location_id: "",
        size: "",
        name: "",
        post_larva_stocked: "",
        cultivation_date: "",
        initial_abw: ""
      },
      formName: "pondEdit",
      ekmm__backndfieldToFieldNameMap: {
        title: "Comn_pond_name",
        name: "Comn_location.lower.case",
        size: "Map_pond_size",
        post_larva_stocked: "Pond_pls_stocked",
        initial_abw: "Comn_initial_abw"
      },
      ekmm__backndfieldToInvalidMap: {
        title: { FIELD_REQUIRED: "Comn_title_required" },
        size: { INVALID_VALUE: "Map_pond_size_greater0" },
        post_larva_stocked: {
          VALUE_SHOULD_BE_NUMBER: "pls_stocked_required",
          INVALID_VALUE: "pls_stocked_required",
          VALUE_SHOULD_BE_A_SAFE_NUMBER: "pls_stocked_safe_number"
        },
        initial_abw: {
          INVALID_VALUE: "Comn_initial_abw_required",
          VALUE_SHOULD_BE_NUMBER: "Comn_initial_abw_greater_than_zero"
        }
      },
      isError: false,
      actualPondSizeAC: 0,
      actualPondSizeHA: 0,
      minArea: 0,
      maxArea: this.maxArea,
    };
  },
  computed: {
    ...mapGetters("googleMaps", {
      currentPond: "getCurrentHoverPond",
      mapMode: "getMapMode"
    }),
    ...mapGetters("user", {
      getCurrUserLocation: "getCurrUserLocation",
      getUserLocationsObj: "getUserLocationsObj",
      getUserProfile: "getUserProfile",
      getUserCountryName: "getUserCountryName",
      getPreferredUnits: "getPreferredUnits"
    }),
    units: function () {
      return {
        ha: this.$t("Pond_size_units_ha"),
        ac: this.$t("Pond_size_units_ac")
      };
    },
    pickerOptions: function () {
      const { cultivations } = this.formPond;
      return {
        disabledDate: (time) => {
          if (cultivations.length === 0) {
            return time.getTime() > Date.now();
          } else {
            return (
              time.getTime() <=
                this.dhm__castUserUTCToUTCDateObj(
                  cultivations[cultivations.length - 1].end_date
                ).getTime() || time.getTime() > Date.now()
            );
          }
        }
      };
    },
    currUserLocation() {
      return this.getCurrUserLocation;
    }
  },
  watch: {
    currentPond: {
      handler: async function (newVal, oldVal) {
        if (this.mapMode === "EDITING") {
          this.formPond = newVal;
          this.formPond.units = oldVal.units;
          this.formPond.size = +this.$commonUtils.castPondAreaSize(
            +this.formPond.size,
            this.getPreferredUnits.pond_area_units,
            this.formPond.units
          );
          await this.areaConversionFunction(this.formPond.size);
          this.actualPondSizeHA = await parseFloat((this.actualPondSizeAC / 2.47105).toFixed(3));
          await this.handleSizeChange(this.formPond.size);
        }
      }
    },
    "formPond.units"(newVal, oldVal) {
      this.newunit = newVal;
      this.oldunit = oldVal;
    },
    "formPond.size"(newVal, oldVal) {
      this.newsize = newVal;
      this.oldsize = oldVal;
      if (parseFloat(oldVal) === 0) {
        if (!this.actualPondSizeAC > 0) {
          this.areaConversionFunction(newVal);
        }
      }
      this.actualPondSizeHA = parseFloat((this.actualPondSizeAC / 2.47105).toFixed(3));
    }
  },
  mounted() {
    this.initComponent();
  },
  methods: {
    ...mapActions("pond", {
      fetchAllPonds: "fetchAllPonds"
    }),
    ...mapActions("user", {
      mixPanelEventGenerator: "mixPanelEventGenerator"
    }),
    initComponent() {
      this.formPond = this.currentPond;
      this.noChangeInLocation = false;
      this.formPond.initial_abw = this.formPond.initial_abw_id
        ? this.formPond.initial_abw_id.abw
        : 0.1;
      this.currPondLocation =
        this.getUserLocationsObj[this.formPond.location_id].name;
      this.formPond.size = +this.$commonUtils.castPondAreaSize(
        +this.formPond.size,
        this.formPond.units,
       this.getPreferredUnits.pond_area_units
      );
      this.formPond.units = this.getPreferredUnits.pond_area_units;
      this.ehm__errMessagesObject = {
        title: "",
        location_id: "",
        size: "",
        name: "",
        post_larva_stocked: "",
        cultivation_date: "",
        initial_abw: ""
      };
    },
    ekmm__errorLangKeyMapper: function (key, error_code) {
      if (error_code === "VALUE_SHOULD_BE_A_SAFE_NUMBER") {
        return this.$t(this.ekmm__backndfieldToInvalidMap[key][error_code]);
      } else if (error_code === "INVALID_VALUE") {
        return this.$t(this.ekmm__backndfieldToInvalidMap[key][error_code]);
      } else if (error_code === "VALUE_SHOULD_BE_NUMBER") {
        return this.$t(this.ekmm__backndfieldToInvalidMap[key][error_code]);
      } else if (error_code === "FIELD_REQUIRED") {
        return this.$t(this.ekmm__backndfieldToInvalidMap[key][error_code]);
      }
    },
    resetEditingMode: function (event = null) {
      this.ehm__errMessagesObject = {
        title: "",
        location_id: "",
        size: "",
        name: "",
        post_larva_stocked: "",
        cultivation_date: "",
        initial_abw: ""
      };
      const pondId = this.map.currentOverlay.get("pond_id");
      const overlay = this.map.overlays.get(pondId);
      if (overlay && event != null) {
        this.map.setMap(overlay, true);
      }
      if (this.map.currentOverlay != null) {
        this.map.setMap(this.map.currentOverlay, false);
        this.map.currentOverlay = null;
      }
      this.map.mapData.revertStyle();
      this.$store.dispatch("googleMaps/setMapMode", "INITIAL");
      this.map.addAllListeners();
      this.area = 0;
    },
    handleSizeChange(pondSize) {
      // this.formPond.size = this.$commonUtils.castPondAreaSize(
      //   +pondSize,
      //   this.formPond.units,
      //  this.getPreferredUnits.pond_area_units
      // ).toFixed(1);
      this.formPond.size = Number(pondSize);
      // if (this.formPond.units === 'ha') {
      //   this.checkMaximumPondSize(Number(pondSize), this.actualPondSizeHA)
      // } else {
      //   this.checkMaximumPondSize(Number(pondSize), this.actualPondSizeAC)
      // }
    },
    checkMaximumPondSize(value, actualPondSize) {
      const minArea = parseFloat((actualPondSize - (actualPondSize / 100) * 20).toFixed(2).slice(0, -1));
      const maxArea = parseFloat((actualPondSize + (actualPondSize / 100) * 20).toFixed(2).slice(0, -1));
      console.log("actualPondSize", actualPondSize, value, maxArea, minArea);
      if (parseFloat(value.toFixed(2).slice(0, -1)) > maxArea || parseFloat(value.toFixed(2).slice(0, -1)) < minArea) {
        this.isError = true;
        this.minArea = minArea;
        this.maxArea = maxArea;
      } else {
        this.isError = false;
      }
      return this.maxArea;
    },
    handleChangeAreaUnits(newUnitsSelected) {
      this.formPond.units = newUnitsSelected;
      if (this.formPond.units === "ha") {
        const size = +Number(
          this.$commonUtils.castPondAreaSize(
            +this.formPond.size,
            "ac",
            this.formPond.units
          )
        );
        this.formPond.size = parseFloat(size.toFixed(3)); // +this.ftm__digitPrecision(size, 1);
      } else {
        const size = +Number(
          this.$commonUtils.castPondAreaSize(
            +this.formPond.size,
            "ha",
            this.formPond.units
          )
        );
        this.formPond.size = parseFloat(size.toFixed(3));
      }
      //  if (newUnitsSelected === 'ha') {
      //   this.checkMaximumPondSize(this.formPond.size, this.actualPondSizeHA)
      // } else {
      //   this.checkMaximumPondSize(this.formPond.size, this.actualPondSizeAC)
      // }
    },
    handleChangeInLocation(value) {
      this.currPondLocation = value.trim().toLowerCase();
      this.noChangeInLocation = true;
    },
    ehm__error409Handler: function (err) {
      if (err.response.data.error_code === "POND_TITLE_ALREADY_TAKEN") {
        this.$notify({
          title: this.$t("failed"),
          message: this.$t("Ponds_name_already_in_use_choose_different"),
          type: "error",
          duration: 4500
        });
      } else {
        this.$notify({
          title: this.$t("failed"),
          message: this.$t("Something_went_wrong"),
          type: "error",
          duration: 4500
        });
      }
    },
    checkDiffLocations(pondsArray) {
      const techNameFn = (acc, x) => {
        if (
          x._id === this.formPond._id &&
          x.location_id !== this.formPond.location_id
        ) {
          return acc;
        }
        acc.push(
          ["title"].map((key) => x[key].toLowerCase().trim()).join("__")
        );
        return acc;
      };
      const existingTitles = new Set(pondsArray.reduce(techNameFn, []));
      const islocationNameAlreadtTaken = existingTitles.has(
        ["title"]
          .map((key) => this.formPond[key].toLowerCase().trim())
          .join("__")
      );
      return islocationNameAlreadtTaken;
    },
    checkSameLocationCondition(pondsArray) {
      const techNameFn = (acc, x) => {
        if (x._id === this.formPond._id) {
          return acc;
        }
        acc.push(
          ["title"].map((key) => x[key].toLowerCase().trim()).join("__")
        );
        return acc;
      };
      const existingTitles = new Set(pondsArray.reduce(techNameFn, []));
      const islocationNameAlreadtTaken = existingTitles.has(
        ["title"]
          .map((key) => this.formPond[key].toLowerCase().trim())
          .join("__")
      );
      return islocationNameAlreadtTaken;
    },
    saveDetails: async function () {
      const loading = this.$loading({ fullscreen: true });
      try {
        this.ehm__errMessagesObject = {
          title: "",
          location_id: "",
          size: "",
          name: "",
          post_larva_stocked: "",
          cultivation_date: "",
          initial_abw: ""
        };
        let isNewLocation = false;
        const arrLocations = Object.values(this.getUserLocationsObj);
        const foundPlaceIndex = arrLocations
          .map((x) => x.name)
          .indexOf(this.currPondLocation.toLowerCase());
        let location;
        if (foundPlaceIndex === -1) {
          location = await this.$store.dispatch("user/createLocation", {
            name: this.currPondLocation.toLowerCase()
          });
          isNewLocation = true;
        } else {
          location = arrLocations[foundPlaceIndex];
        }
        this.formPond.location_id = location._id;

        const pondNames = await this.fetchAllPonds({
          location_id: location._id,
          get_all: true,
          status: ["ACTIVE", "INACTIVE"]
        });

        const arrayOfPonds = this.noChangeInLocation
          ? this.checkDiffLocations(pondNames.ponds)
          : this.checkSameLocationCondition(pondNames.ponds);
        if (arrayOfPonds) {
          this.ehm__errMessagesObject.title = this.$t(
            "pond-name-already-taken"
          );

          return false;
        }
        // adding timezone to the formPond object
        this.formPond.timezone = this.getUserProfile.timezone;
        const errorgenerator = (error_code, error_key) => {
          throw {
            response: {
              status: 422,
              data: {
                errors: {
                  details: [{ error_code: error_code, key: error_key }]
                }
              }
            }
          };
        };
        if (this.formPond.initial_abw === undefined) {
          errorgenerator("INVALID_VALUE", "initial_abw");
        } else if (this.formPond.initial_abw <= 0) {
          errorgenerator("VALUE_SHOULD_BE_NUMBER", "initial_abw");
        } else {
          const payload = {
            title: this.upm__trimSpacesInStr(this.formPond.title),
            pond_id: this.formPond._id,
            pond_coordinates: this.formPond.pond_coordinates,
            timezone: this.formPond.timezone,
            location_id: this.formPond.location_id,
            size: this.formPond.size || null,
            units: this.formPond.units,
            post_larva_stocked: +this.formPond.post_larva_stocked,
            cultivation_date: this.formPond.cultivation_date,
            initial_abw: this.formPond.initial_abw
          };
          if (this.currUserLocation._id === this.formPond.location_id) {
            await this.$store.dispatch("pond/updatePondDetails", payload);
            this.$notify({
              title: this.$t("Usrs_success_msg"),
              message: this.$t("Pond_details_save_success"),
              duration: 5000,
              type: "success"
            });
            this.mixPanelEventGenerator({ eventName: "Map - Edit Pond - Pond Details - Save" });
            loading.close();
          } else {
            await this.nofm__primaryConfirmation(
              this.upm__trimSpacesInStr(this.formPond.title),
              this.$t("Comn_add").toLowerCase(),
              this.$t("Comn_to").toLowerCase(),
              this.ftm__capitalize(location.name)
            );

            await this.$store.dispatch("pond/updatePondDetails", payload);
            this.$notify({
              title: this.$t("Usrs_success_msg"),
              message: this.$t("pond-updated-successfully"),
              duration: 5000,
              type: "success"
            });
            this.mixPanelEventGenerator({ eventName: "Map - Edit Pond - Pond Details - Save" });
          }
          loading.close();
          if (isNewLocation) {
            await this.$store.dispatch("user/fetchUserLocations");
            await this.$store.dispatch("user/updateCurrUserLocation", location);
            this.rdm__navigateToPathWithLocationId({
              query: { ...this.$route.query, location_id: location._id }
            });
          } else if (
            this.getCurrUserLocation._id !== this.currPondLocation._id
          ) {
            await this.$store.dispatch("user/updateCurrUserLocation", location);
            this.rdm__navigateToPathWithLocationId({
              query: { ...this.$route.query, location_id: location._id }
            });
          }
          this.$emit("refetch_data");
        }
      } catch (err) {
        // this.initComponent();
        this.ehm__errorMessages(err, true);
      } finally {
        loading.close();
      }
    },
    areaConversionFunction(pondSize) {
      if ((this.getPreferredUnits.pond_area_units === 'ha' && this.formPond.units === 'ha') ||
          (this.getPreferredUnits.pond_area_units === 'ac' && this.formPond.units === 'ha')) {
        this.actualPondSizeAC = parseFloat((pondSize * 2.47105).toFixed(3))
      }
      if ((this.getPreferredUnits.pond_area_units === 'ac' && this.formPond.units === 'ac') ||
          (this.getPreferredUnits.pond_area_units === 'ha' && this.formPond.units === 'ac')) {
        this.actualPondSizeAC = parseFloat(pondSize.toFixed(3))
      }
    }
  }
};
</script>

<style lang="scss">
#edit-pond {
  position: absolute;
  top: 0px;
  left: 0px;
  .edit-pond-input-size {
    width: 100%;
    .el-input__inner {
      text-align: left;
    }
  }
  .action-display-panel {
    display: flex;
    flex-direction: column;
    align-content: center;
    &.el-card {
      margin: 10px;
      @include normal-text;
    }
    .btn-group {
      margin-top: 10px;
      display: flex;
      justify-content: center;
      .el-button {
        font-size: inherit;
      }
    }
    .action-display-area-input {
      display: flex;
      flex-direction: row;
      justify-content: space-around;
    }
    .el-input {
      width: 100%;
      font-size: inherit;
    }
    .el-card__header,
    .el-card__body {
      padding: 10px 10px;
    }
    .input-label {
      display: inline-block;
      width: fit-content;
      margin: auto;
    }
    .select-location {
      .el-input__inner {
        text-transform: capitalize;
      }
    }
  }
}
.action-display-panel {
  background: #fcfcfc;
  border-radius: 10px;

  .el-input--small .el-input__inner {
    @include responsiveProperty(width, 185px, 190px, 250px);
  }
  .el-input-number--small {
    @include responsiveProperty(width, 185px, 190px, 250px);
  }

  .el-form-item--small .el-form-item__content,
  .el-form-item--small .el-form-item__label {
    @include responsiveProperty(
      font-size,
      $app_font_size_1,
      $app_font_size_2,
      $app_font_size_3
    );
    @include responsiveProperty(min-width, 120px, 145px, 170px);
  }
  .error-msg {
    color: #f56c6c;
    font-size: 12px;
    line-height: 1.5;
    margin-bottom: -10px;
  }
}
</style>
