<!-- Copyright (C) Eruvaka Technologies Pvt Ltd - All Rights Reserved * Unauthorized copying of this file, via any medium is strictly prohibited * Proprietary and confidential * 2021 -->
<!--
File Name: pmFeedingTab.vue
Description: This file is the container component shown in schdeules pondmother tab. It contains UI components that hold ponds list shown on the left panel and collaple pond & pondmothers shown on the left
-->
<template>
  <el-row class="pm-feeding-tab">
    <el-col :span="6" :md="6" :lg="5" :xl="4">
      <div v-if="getIsPondListLoading">
        <loader></loader>
      </div>
      <ponds-list
        :value="getSelectedPond"
        :listOfPonds="listOfPondDetails"
        unique-id-key="_id"
        v-else
        @click="initPondSchedDetails"
      >
        <template slot="list-header">
          <el-card shadow="never" class="list-item-card-holder">
            <layout-toolbar class="list-item__row">
              <div class="list-item__title">
                {{ ftm__uppercase($t("Comn_ponds")) }}
              </div>
              <div
                :class="[
                  getColumnLength === 2 ? 'feed-header-one' : 'feed-header'
                ]"
                v-show="checkContainer.feed"
              >
                <div class="filler"></div>
                <span>{{ $t('Comn_feed') }}</span>
              </div>
              <div class="count-header" v-show="checkContainer.count">
                <div class="filler"></div>
                <span>{{ $t('count') }}</span>
              </div>
              <er-dropdown
                placement="bottom"
                width="200"
                @item-click="handleItemClick"
                @command-click="handlePondActions"
                :hideOnClick="false"
                trigger="click"
                :listOfItems="listItemsContainer"
                :disabled="listOfPondDetails.length === 0"
                popperClass="pm-feeding-tab__schedule-actions-popper"
              >
                <template slot="suffix">
                  <span class="material-icons-round trigger-icon">
                    more_vert
                  </span>
                </template>
                <template v-slot:drop-down-items>
                  <el-dropdown-item :command="listItemsContainer[0].value">
                    <layout-toolbar>
                      <div
                        style="width: 50%;font-size: 13px;margin-left: -6px;"
                      >
                        {{ listItemsContainer[0].title_lang() }}
                      </div>
                      <div>
                        <el-checkbox
                          v-model="checkContainer.feed"
                          disabled
                          class="list-item__value checkbox-feed"
                        >
                        </el-checkbox>
                      </div>
                      <!-- <div class="filler"></div> -->
                    </layout-toolbar>
                  </el-dropdown-item>
                  <el-dropdown-item :command="listItemsContainer[1].value">
                    <layout-toolbar>
                      <div
                        style="width: 50%; font-size: 13px;margin-left: -6px;"
                      >
                        {{ listItemsContainer[1].title_lang() }}
                      </div>
                      <div>
                        <el-checkbox
                          v-model="checkContainer.count"
                          class="list-item__value checkbox-pmCount"
                          @change="handleCountCheckBoxChange($event)"
                        >
                          <!-- {{ "PM Count" }} -->
                        </el-checkbox>
                      </div>
                    </layout-toolbar>
                  </el-dropdown-item>
                  <el-dropdown-item :command="listItemsContainer[2].value">
                    <er-dropdown
                      placement="bottom"
                      width="200"
                      @item-click="handlePondActions"
                      trigger="click"
                      :listOfItems="scheduleActionItems"
                      :disabled="listOfPondDetails.length === 0"
                      popperClass="pm-feeding-tab__schedule-actions-popper-second"
                    >
                      <template v-slot:item-title="{ item }">
                        <span class="material-icons-round menu-item__icon">{{
                          item.value.icon
                        }}</span>
                        <span class="menu-item__title">{{
                          ftm__capitalize(item.value.title_lang())
                        }}</span>
                      </template>
                      <template slot="title">
                        {{ listItemsContainer[2].title_lang() }}
                      </template>
                    </er-dropdown>
                  </el-dropdown-item>
                </template>
              </er-dropdown>
            </layout-toolbar>
          </el-card>
        </template>
        <template v-slot:list-item="{ pond }">
          <el-card shadow="never" :class="['list-item-card-holder']">
            <el-row
              type="flex"
              :class="[
                'list-item__row',
                getColumnLength === 2 ? 'width-one' : 'width-two'
              ]"
            >
              <svg
                :class="[
                  getColumnLength === 2
                    ? 'list-item__color-swatch-one'
                    : 'list-item__color-swatch'
                ]"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle
                  cx="7.5"
                  cy="7.5"
                  r="7.5"
                  :fill="getPondMode(pond).color"
                >
                  <title>{{ getPondMode(pond).translation }}</title>
                </circle>
              </svg>
              <el-col tag="p" class="list-item__title" :title="pond.title">
                {{ pond.title }}
              </el-col>
              <el-col
                v-if="checkContainer.feed"
                tag="p"
                :class="[
                  getColumnLength === 2
                    ? 'list-item-feed__value-one'
                    : 'list-item-feed__value'
                ]"
              >
                <span v-show="checked">
                  {{ ftm__digitPrecision(pond.dispensed_feed, 1) }}/{{
                    ftm__digitPrecision(pond.feed, 1)
                  }}
                </span>
              </el-col>
              <el-col
                v-if="checkContainer.count"
                tag="p"
                :class="[
                  getColumnLength === 2
                    ? 'list-item-pmcount__value-one'
                    : 'list-item-pmcount__value'
                ]"
              >
                <span v-show="pmCountChecked">
                  {{ getPmCountBasedOnPondId(pond._id) }}
                </span>
              </el-col>
            </el-row>
          </el-card>
        </template>
      </ponds-list>
    </el-col>
    <el-col :span="18" :md="18" :lg="19" :xl="20">
      <pond-schedule-details></pond-schedule-details>
    </el-col>
    <pond-schedule-action-dialog
      :dialog-visible="showPondScheduleActionDialog"
      :action="pondScheduleAction"
      @close-dialog="handleScheduleActionClose"
    ></pond-schedule-action-dialog>
  </el-row>
</template>

<script>
import pondsList from "@/components/pmSchedules/pondsList";
import pondScheduleDetails from "./pondScheduleDetails";
import errorHandlerMixin from "@/mixins/errorHandlerMixin";
import urlParamsUpdateMixin from "@/mixins/urlParamsUpdateMixin";
import pmSchedulesMixin from "@/mixins/pmSchedulesMixin";
import { mapGetters, mapActions, mapMutations } from "vuex";
import Pond from "@/model/pond";
import Loader from "@/components/base/Loader";
import pondScheduleActionDialog from "@/components/pmSchedules/pondScheduleActionDialog";
import localStore from "@/utils/localStore";
export default {
  components: {
    pondsList,
    pondScheduleDetails,
    Loader,
    pondScheduleActionDialog
  },
  props: ["tabData"],
  mixins: [errorHandlerMixin, urlParamsUpdateMixin, pmSchedulesMixin],
  data: function() {
    return {
      listLoading: false,
      scheduleDetailsLoading: false,
      checked: true,
      checkContainer: {
        feed: true,
        count: false
      },
      pmCountChecked: true,
      pondScheduleAction: {},
      showPondScheduleActionDialog: false,
      valueDateFormat: "yyyy-MM-dd"
    };
  },
  watch: {
    getSelectedPond: function(nPond, oPond) {
      if (!nPond || !nPond._id) return;
      this.upum__setUrlParams("pond", nPond._id);
    },
    getDateRange: function(nDateRange, oDateRange) {
      if (!nDateRange || nDateRange.length === 0) return;
      this.upum__setUrlParams("min_date", nDateRange[0]);
      this.upum__setUrlParams("max_date", nDateRange[1]);
    }
  },
  computed: {
    ...mapGetters("pondMotherSchedules", {
      getPondDetailsToPondIdHavingPms: "getPondDetailsToPondIdHavingPms",
      getIsPondListLoading: "getIsPondListLoading",
      getIsPondSchedLoading: "getIsPondSchedLoading",
      getPondModeByPmDetailsInPond: "getPondModeByPmDetailsInPond",
      getExistSchedDtSecsToPondIdToPondSchedDtls:
        "getExistSchedDtSecsToPondIdToPondSchedDtls",
      getAllPondsPmDetailsToPmId: "getAllPondsPmDetailsToPmId",
      getDateRange: "getDateRange",
      getSelectedPond: "getSelectedPond",
      getSelectedDay: "getSelectedDay",
      getPmCount: "getPmCount"
    }),
    getColumnLength() {
      return Object.keys(this.checkContainer).filter((item) => {
        return this.checkContainer[item] === true;
      }).length;
    },
    hasPonds() {
      return Object.keys(this.getPondDetailsToPondId || {}).length > 0;
    },
    hasSchedules() {
      return (
        Object.keys(this.getExistSchedDtSecsToPondIdToPondSchedDtls || {})
          .length > 0
      );
    },
    hasPms() {
      return Object.keys(this.getAllPondsPmDetailsToPmId || {}).length > 0;
    },
    listItemsContainer: function() {
      return [
        {
          title_lang: () => this.$t("Comn_feed"),
          key: "FEED",
          value: "FEED",
          isChecked: true
        },
        {
          title_lang: () => this.$t("count"),
          value: "COUNT",
          key: "COUNT",
          isChecked: false
        },
        {
          title_lang: () => this.$t("Comn_actions"),
          value: "ACTION",
          key: "ACTION"
        }
      ];
    },
    scheduleActionItems: function() {
      return {
        RUNNING: {
          appliesOn: ["PAUSED"],
          title_lang: () => this.$t("PM_play"),
          icon: "play_arrow",
          key: "RUNNING"
        },
        PAUSED: {
          appliesOn: ["RUNNING"],
          title_lang: () => this.$t("PM_pause"),
          icon: "pause",
          key: "PAUSED"
        },
        STOPPED: {
          appliesOn: ["PAUSED", "RUNNING"],
          title_lang: () => this.$t("PM_stop"),
          icon: "stop",
          key: "STOPPED"
        }
      };
    },
    listOfPondDetails() {
      const data = Object.values(
        this.getPondDetailsToPondIdHavingPms || {}
      ).sort((a, b) =>
        this.$commonUtils.alphaNumericComparator(a.title, b.title)
      );
      return data;
    },

    getPmCountBasedOnPondId() {
      return (pondId) => {
        return this.getPmCount.filter((item) => item.pond_id === pondId)[0]?.pond_mothers_count || 0
      };
    },

    getPondMode() {
      const translations = {
        SHRIMP_TALK: this.$t("PM_automatic"),
        HYBRID: this.$t("hybrid"),
        FARMER: this.$t("Comn_schedule"),
        MANUAL: this.$t("PM_sch_mode4")
      };
      return (pond) => {
        const mode = pond.mode || this.getPondModeByPmDetailsInPond(pond._id);
        const color = (Pond.generateColorForMode(mode) || { color: "#000000" })
          .color;
        return { mode, color, translation: translations[mode] };
      };
    }
  },
  async mounted() {
    await this.initDateRange();
    await this.initComponent();
  },
  created() {
    // const data = JSON.parse(localStore.getItem("selected-item") || []);
    const data = localStorage.getItem("selected-item") ? JSON.parse(localStorage.getItem("selected-item") ?? []) : [];
    const setData = Object.keys(data);
    setData.map((item) => {
      const flag = this.checkContainer.hasOwnProperty(item);
      if (flag) {
        this.checkContainer[item] = data[item];
      }
    });
  },
  methods: {
    ...mapActions("pondMotherSchedules", {
      fetchListOfPondsWithSchedDetails: "fetchListOfPondsWithSchedDetails",
      changeDateQueryType: "changeDateQueryType",
      fetchAllPondDetails: "fetchAllPondDetails",
      changeDateRange: "changeDateRange",
      refreshPondSchedules: "refreshPondSchedules",
      clearPondTSTableData: "clearPondTSTableData",
      changeInExpandPmKeys: "changeInExpandPmKeys",
    }),
    ...mapMutations("pondMotherSchedules", {
      INIT_DATE_RANGE: "INIT_DATE_RANGE",
      INIT_SELECTED_DAY: "INIT_SELECTED_DAY",
      INIT_SELECTED_POND: "INIT_SELECTED_POND",
      INIT_PERMISSIONS: "INIT_PERMISSIONS"
    }),
    ...mapActions("user", {
      mixPanelEventGenerator: "mixPanelEventGenerator"
    }),
    handleCountCheckBoxChange(value) {
      if (value) {
        this.mixPanelEventGenerator({ eventName: "Feeding - PondMother - Count" });
      }
    },
    async initComponent() {
      try {
        this.INIT_PERMISSIONS(this.tabData);
        if (this.upum__urlParams.pond) {
          this.INIT_SELECTED_POND({ _id: this.upum__urlParams.pond });
        }
        await this.changeDateRange();
        // await this.fetchListOfPondsWithSchedDetails();
      } catch (err) {
        this.ehm__errorMessages(err, true);
      }
    },
    async initPondSchedDetails(selectedPond) {
      if (!selectedPond) return;
      if (this.getIsPondSchedLoading) return;
      try {
        this.changeInExpandPmKeys({
          pondId: this.getSelectedPond._id,
          pmId: []
        });
        await this.clearPondTSTableData({
          days: [this.getSelectedDay]
        });
        this.INIT_SELECTED_POND(selectedPond);
        await this.changeDateRange();
        this.mixPanelEventGenerator({ eventName: "Feeding - PondMother - Pond Selection" })
      } catch (err) {
        this.ehm__errorMessages(err, true);
      }
    },
    handleCheckbox(event) {
      console.log(event);
    },
    initDateRange() {
      try {
        const getDefaultDates = () => {
          return [1, 2].map((_) =>
            this.dhm__dateUtilsLib.formatDate(
              this.dhm__dateUtilsLib.utcToZonedTime(
                new Date(),
                this.getUserTimeZoneString
              ),
              this.valueDateFormat
            )
          );
        };
        let initStartDate = this.upum__urlParams.min_date;
        let initEndDate = this.upum__urlParams.max_date;
        if (!initStartDate || !initEndDate) {
          [initStartDate, initEndDate] = getDefaultDates();
        }
        if (
          this.getValidDateQueryType(
            initStartDate,
            initEndDate,
            this.valueDateFormat,
            false
          ) === "INVALID_RANGE"
        ) {
          [initStartDate, initEndDate] = getDefaultDates();
        }
        [initStartDate, initEndDate] = [initStartDate, initEndDate].map((x) =>
          this.dhm__dateUtilsLib.formatDate(
            this.dhm__dateUtilsLib.parse(x, this.valueDateFormat, new Date()),
            this.valueDateFormat
          )
        );
        const dateQueryType = this.getValidDateQueryType(
          initStartDate,
          initEndDate,
          this.valueDateFormat
        );
        this.INIT_DATE_RANGE([initStartDate, initEndDate]);
        this.changeDateQueryType(dateQueryType);
        const isoDateString = `${initStartDate}T00:00:00.000Z`;
        const s_date = this.dhm__dateUtilsLib.utcToZonedTime(
          this.dhm__castUserUTCToUTCDateObj(isoDateString),
          this.getUserTimeZoneString
        );
        const s_date_secs = s_date.getTime();
        this.INIT_SELECTED_DAY(s_date_secs);
      } catch (err) {
        this.ehm__errorMessages(err, true);
      }
    },
    handlePondActions(action) {
      try {
        // this.$gblUAMCanUserEdit(this.tabData);
        this.pondScheduleAction = action;
        this.showPondScheduleActionDialog = true;
      } catch (err) {
        this.ehm__errorMessages(err, true);
      }
    },
    handleItemClick(action) {
      const flag = ["FEED", "COUNT"].includes(action);
      if (flag) {
        setTimeout(() => {
          localStore.setItem("selected-item", this.checkContainer);
        }, 500);
      }
    },
    handleScheduleActionClose(triggerName) {
      const obj = {
        RUNNING: "Paly",
        PAUSED: "Pause",
        STOPPED: "Stop"
      }
      if (triggerName === 'save-button-success') {
        this.mixPanelEventGenerator({ eventName: "Feeding - PondMother - Actions - " + obj[this.pondScheduleAction.key] + " - Save" });
      }
      this.pondScheduleAction = undefined;
      this.showPondScheduleActionDialog = false;
    }
  }
};
</script>
<style lang="scss">
.pm-feeding-tab {
  // .er-icon-caret {
  //   transform: rotate(90deg) !important;
  // }
  // .is-reverse {
  //   -webkit-transform: rotate(270deg);
  //   transform: rotate(270deg) !important;
  //   color: #606266;
  // }
  .el-card__body {
    padding: 8px 5px !important;
  }
}
.count-header {
  display: flex;
  flex-grow: 1;
  flex-shrink: 0;
  // margin-right: 4px;
  .filler {
    flex-grow: 0.95;
  }
}
.feed-header {
  display: flex;
  flex-grow: 0;
  flex-shrink: 0;
}
.feed-header-one {
  display: flex;
  flex-grow: 0.5;
  flex-shrink: 0;
}
#dropdown-menu-6765 {
  top: 167px;
  left: 372px;
}
.el-checkbox__inner {
  width: 13px;
  height: 13px;
}
.er-dropdown-trigger__title,
.menu-item__title {
  font-size: 13px !important;
}
.width-one {
  width: 90%;
}
.width-two {
  width: 70%;
}
.el-dropdown-menu {
  .el-row {
    display: flex;
    width: 100%;
    justify-content: space-around;
  }
}
.list-item__row:nth-last-child(2) {
  margin-left: 20px;
}
.pm-feeding-tab__schedule-actions-popper {
  .el-dropdown-menu__item {
    font-size: 13px !important;
    padding: 0 10px;
  }
  span + span.menu-item__title {
    padding-left: 5px;
  }
  .er-dropdown-trigger__suffix {
    margin-left: 12px !important;
  }
}
.pm-feeding-tab__schedule-actions-popper-second {
  // top: 155px !important;

  // left: 389px !important;

  // @media screen and (max-width: 1439px){
  //    left: 389px !important;
  // }
  // @media screen and (min-width: 1440px){
  //    left: 370px !important;
  // }
  .el-dropdown-menu__item {
    font-size: 13px !important;
    padding: 0 10px;
  }
  span + span.menu-item__title {
    padding-left: 5px;
  }
}
.list-item__row {
  justify-content: space-between !important;
}
.pm-feeding-tab {
  height: 100%;
  > .el-col {
    height: 100%;
  }
  .ponds-list__item .small-text {
    background-color: #f4f5f9;
  }
  .item-container {
    width: 81%;
  }
  .ponds-list__item:hover {
    .list-item-card-holder .el-card__body {
      border-left: 3px solid #f4f5f9;
      background-color: #f4f5f9;
    }
  }
  .ponds-list__item.selected {
    .list-item-card-holder {
      // width: 64% !important;

      .el-card__body {
        border-left: 3px solid #508ff4;
        background-color: aliceblue;
      }
    }
  }
  .list-item-card-holder {
    color: #0a2463;
    border-radius: unset;
    border: unset;
    border-bottom: 1px solid #c9cfd438;
    margin-bottom: 1px;
    .el-card__body {
      border-left: 3px solid #ffffff;
      padding: 8px 5px !important;
      .list-item__row {
        align-items: center;
        .list-item__title {
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
        .list-item-feed__value {
          display: flex;
          justify-content: center;
          // margin-left: 5px;
          // margin-left: -87px;
        }
        .list-item-feed__value-one {
          display: flex;
          justify-content: flex-start;
          // margin-left: 5px;
          // margin-left: -87px;
        }
        .list-item-pmcount__value {
          display: flex;
          justify-content: center;
        }
        .list-item-pmcount__value-one {
          display: flex;
          justify-content: center;
        }
        .list-item__color-swatch-one {
          margin-right: 10px;
          width: 30px !important;
          @include icon-size(svg);
        }
        .list-item__color-swatch {
          margin-right: 10px;
          width: 22px !important;
          @include icon-size(svg);
        }
      }
    }
  }
  // .checkbox-feed {
  //   width: 33%;
  // }
  // .checkbox-pmCount {
  //   width: 33%;
  // }
  .ponds-list__item-header .list-item-card-holder {
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    .list-item__title {
      // margin-left: 10%;
      font-weight: 600;
    }
    .el-checkbox__input {
      .el-checkbox__inner {
        width: 12px;
        height: 12px;
        &:after {
          border-color: #0a2463;
        }
      }
      &.is-checked .el-checkbox__inner,
      &.is-indeterminate .el-checkbox__inner {
        background-color: #fff;
      }
      &.is-checked + .el-checkbox__label {
        color: #0a2463;
      }
    }
    .el-checkbox__label {
      text-transform: capitalize;
      @include responsiveProperty(
        font-size,
        $app_font_size_v_medium,
        $app_font_size_small + 1,
        $app_font_size_1 + 2
      );
    }
  }
  .ponds-list__item-footer:not(.selected) .list-item-card-holder {
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
  }
  .er-dropdown-trigger__suffix .trigger-icon {
    vertical-align: middle;
  }
}
</style>
